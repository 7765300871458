$primary: #336ced; /* MAIN COLOR */
$secondary: #143889; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Libre+Franklin|Patua+One');

h1,h2,h3 {
	font-family: 'Patua One', cursive;
}
p {
	font-family: 'Libre Franklin', sans-serif;
	font-size: 1.7em;
	@media (max-width: 767px) {
		font-size: 1.2em;
	}

}
.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/** NAV START **/
nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;
	
	.navbar-nav {

	    > li > a {
			text-align: center;
			height: 80px;
			display: flex;
			align-items: center;


			@media (max-width: 767px) {
			    height: 28px;
			    padding: 5px;
			    display: inline-block;
			}

		    &:hover {
		    	background: $primary;
		    	color: lighten($primary, 50%);
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: #777;
				  	outline: 0;		
		    	}
		    }

			&:focus {
			  background: transparent;
			  color: #777;
			  outline: 0;
			}
		}
	}
}


.navbar-right {
	margin-top: 0px;
}


.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/
@mixin btn1 {
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}


@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}


.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}
	input#username, input#password {
		@media (max-width: 1024px) {
			font-size: 16px;
		}
	}
	input {
		border: 1px solid #777;
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}



.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}


input#username {
	margin-bottom: 20px;
}


/** END LOGIN FORM **/
footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	@media (max-width: 990px) {
		font-size: 13px;
	}
	p {
		font-size: 16px;
		@media (max-width: 767px) {
			font-size: 1em;
		}	
	}
	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 16px;
		@media (max-width: 767px) {
			font-size: 1em;
		}

		&:hover{
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $wht;
			outline: 0 !important;
 			text-decoration: none;
		}
	}
}


input#username, input#password {
	width: 100%;
}


@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}


select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    } 
}

.banner {
	background: url(../img/banner.jpg) no-repeat;
	background-size: cover;
	position: relative;
	height: 500px;
	padding: 0;
	z-index: 0;
	&:before {
	content: '';
	width: 100%;
	height: 20%;
	position: absolute;
	background: white;
	z-index: 1;
	bottom: -10%;
	transform-origin: right bottom;
	transform: skewY(-3deg);
}
.row {
	padding: 0;
	margin: 0;
}
	@media (max-width: 991px) {
		height: 350px;
		background-position: 40% 50%;
	}
	h1 {
		color: white;
		margin-top: 100px;
		font-size: 4em;
		@media (max-width: 991px) {
			font-size: 3em;
			margin-top: 50px;
		}
		@media (max-width: 767px) {
			display: none;
		}
	}
}

.sideImg {
	background: url(../img/couple1.jpg) no-repeat;
	background-size: cover;
	height: 400px;
	margin-top: 90px;
	background-position: 50%;
	@media (max-width: 991px) {
		margin-top: 0;
	}
	@media (max-width: 767px) {
		background-position: 90% 50%;
	}
}

.midSection {
	background: url(../img/banner2.jpg) no-repeat;
	background-size: cover;
	padding: 250px 0px;
	@media (max-width: 991px) {
		padding: 125px 0px;
	}
	.row {
		padding: 0;
		margin: 0;
	}
	position: relative;
	z-index: 0;
	background-position: 50% 50%;
	&:before {
	background: white;
	content: "";
	height: 150px;
	width: 100%;
	position: absolute;
	top: -20%;
	transform-origin: right bottom;
	transform: skewY(-3deg);
	}
	.txtBox {
		background: rgba($primary, 0.5);
		padding: 30px 15px;
		color: white;
	}
}

.topSection {
	position: relative;
	z-index: 1;
	background: white;
}

body {
	background: url(../img/bg.jpg) no-repeat fixed;
	background-size: cover;
	@media (max-width: 1024px) {
		background: url(../img/bg.jpg) no-repeat;
		background-size: cover;
	}
}

.iconSection {
	background: white;
	padding: 100px 15px;
	@media (max-width: 991px) {
		padding: 50px 15px;
	}
	img {
		max-width: 200px;
		width: 100%;
		display: block;
		margin: 0 auto;
	}
	p {
		font-size: 1.5em;
	}
	.txt {
		font-size: 1em;
	}
}

.btmSection {
	background: $secondary;
	color: white;
	padding: 100px 15px;
	@media (max-width: 767px) {
		padding: 50px 15px;
	}
}

.fixedNav {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 999;
}

.top-pad {
	background: white;
	padding: 0 15px;
	font-family: 'Convergence', sans-serif;
	p {
		color: black;
		font-size: 1em;
	}
	h1,h2,h3 {
		color: $primary;
		font-size: 1.6em;
		margin: 0;
		padding: 10px 0;
	}
	li {
		font-family: 'Convergence', sans-serif;
	}
}